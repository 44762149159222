<template>
	<div>
		<div class="container">
			<div>
				<el-select size="small" v-model="query.userPhone" filterable clearable placeholder="主叫号码"
					class="handle-select mr10" style="width: 200px;">
					<el-option v-for="item in GMRecordAllList" :key="item" :label="item" :value="item"></el-option>
				</el-select>
				<el-select size="small" v-model="query.call" filterable clearable placeholder="请选择"
					class="handle-select mr10" style="width: 200px;">
					<el-option label="全部" value="-1"></el-option>
					<el-option label="未接通" value="0"></el-option>
					<el-option label="已接通" value="1"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="被叫号码"
					class="mr10"></el-input>
				<el-date-picker v-model="timeArr" style="width: 360px;margin-right: 10px;margin-top: 10px;"
					type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" @change="getTime()" size="small"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10"
					@click="getGMRecordLists">查询</el-button>
			</div>
			<el-table v-loading="loading" :data="tableData" border class="table m_t_20"
				header-cell-class-name="table-header" :row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }" :cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName" :key="Math.random()">
				>
				<el-table-column prop="phoneNumber" label="被叫号码" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="主叫号码" align="center"></el-table-column>
				<el-table-column prop="recordPath" label="通话录音" align="center">
					<template #default="scope">
						<audio :id="scope.row.recordPath" controls="controls" preload="none">
							<source :src="scope.row.recordPath" type="audio/mpeg" />
							<source :src="scope.row.recordPath" type="audio/ogg" />
							<source :src="scope.row.recordPath" type="audio/wav" />
						</audio>
					</template>
				</el-table-column>
				<el-table-column prop="callDuration" label="通话时长" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="外呼时间" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getGMRecordList, getGMRecordAll } from '../api/reportNumber.js';
	export default {
		name: 'GM',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					endTime: '',
					startTime: '',
					phoneNumber: '', //主叫号码
					pageIndex: 1,
					pageSize: 30,
					userId: localStorage.getItem('user'),
					userPhone: '' //被叫号码
				},
				pageTotal: 0,
				tableData: [],
				timeArr: [],
				GMRecordAllList: []
			};
		},
		created() {
			this.getGMRecordAlls();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getGMRecordAlls() {
				getGMRecordAll().then(res => {
					if (res.code == 200) {
						this.GMRecordAllList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getGMRecordLists() {
				this.query.phoneNumber = this.query.phoneNumber.replaceAll(' ', '');
				console.log(this.query, 'dddd');
				getGMRecordList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//筛选时间
			getTime() {
				this.timeArr.push(this.query.startTime);
				this.timeArr.push(this.query.endTime);

				this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
				this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();

				let h = date.getHours();
				let min = date.getMinutes();
				let s = date.getSeconds();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				if (h < 10) {
					h = '0' + h;
				}
				if (min < 10) {
					min = '0' + min;
				}
				if (s < 10) {
					s = '0' + s;
				}
				return y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s;
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getGMRecordLists();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getGMRecordLists();
			},
			getswitch(row) {
				let data = {
					phoneNumber: row.phoneNumber
				};
				GMStop(data).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功');
						this.getGMRecordLists();
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		width: 200px;
		margin-right: 10px;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}
</style>
<style>
	.validate .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.validate .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>